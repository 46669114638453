
import Footer from "./components/Footer";
import Header from "./components/Header";
import Loading from "./components/Loading";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminPage from "./AdminPages/AdminPage";
import AdminLogin from "./AdminPages/AdminLogin";
import { useEffect, useState } from "react";
import Hizmetler from "./pages/Hizmetler";
import { onSnapshot, deleteDoc, doc, query,orderBy } from "firebase/firestore";
import { DBkombi } from "./db/Firebase-Collection";
import ColorComponent from "./menagment/ColorComponent";

function App() {
  const[IsLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn") || false);
  const [AllData, setAllData] = useState([]);
  useEffect(() => {
    const q = query(DBkombi)
    const unsubscribe = onSnapshot( q ,(snapshot) => {
      setAllData((snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))[0].data))
    });
    return () => unsubscribe();
  }, []); 

  return (
    <Router>
      <ColorComponent primaryColor={AllData.primaryColor}/>
      <Header contact={AllData.contact} />
      <Routes>
            <Route path="/" element={<HomePage AllData={AllData} />} />
            <Route path="/hakkimizda" element={<AboutPage about={AllData.about}/>} />
            <Route path="/iletisim" element={<ContactPage contact={AllData.contact}/>} />
            <Route path="/hizmetler" element={<Hizmetler AllData={AllData}/>} />
            <Route path="/admin" element={IsLoggedIn ? <AdminPage AllData={AllData}/> : <AdminLogin setIsLoggedIn={setIsLoggedIn}/>} />
            <Route path="*" element={<HomePage />} />
          </Routes>
      <Loading />
      <Footer />
    </Router>
  );
}

export default App;
