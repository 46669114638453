
const CustomPageHead = ({pageName}) => {
  return (
    <div id="inner_banner" className="section inner_banner_section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="full">
            <div className="title-holder">
              <div className="title-holder-cell text-left">
                <h1 className="page-title">{pageName.toUpperCase()}</h1>
                <ol className="breadcrumb">
                  <li><a href="index.html">Anasayfa</a></li>
                  <li className="active">{pageName.toUpperCase()}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CustomPageHead