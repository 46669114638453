import React, { useEffect, useState } from "react";
import ChangeThemeColor from "./components/ChangeThemeColor";
import ContactPanel from "./components/ContactPanel";
import BilgilendirmePanel from "./components/BilgilendirmePanel";
import ServislerPanel from "./components/ServislerPanel";
import BlogPanel from "./components/BlogPanel";
import HakkımızdaPanel from "./components/HakıımızdaPanel";

const AdminPage = ({AllData}) => {
  const [category, setCategory] = useState("")
  useEffect(() => {
    var head = document.getElementById("default_header");
    if(head) head.style.display = "none";
    document.querySelector(".footer_style_2").style.display = "none";
    document.querySelector(".loader").style.display = "none";
    setTimeout(() => {
      document.querySelector(".header_style_1").style.display = "none";
    }, 1500);
    document.title = "Admin - Acil Kombi Servisi"
   }, [])

   const CategoryComponent = () =>{
    if(category=="tema"){
      return(<ChangeThemeColor AllData={AllData}/>)
    }else if(category=="iletisim"){
      return(<ContactPanel contact={AllData.contact}/>)
    }
    else if(category=="servisler"){
      return(<ServislerPanel AllData={AllData}/>)
    }
    else if(category=="bilgilendirme"){
      return(<BilgilendirmePanel AllData={AllData}/>)
    }
    else if(category=="blog"){
      return(<BlogPanel AllData={AllData}/>)
    }
    else if(category=="hakkımızda"){
      return(<HakkımızdaPanel about={AllData?.about} aboutSlide={AllData?.aboutSlide}/>)
    }
    else{
      return (<iframe style={{ padding: "20px", borderTop: "4px solid #2A3542", borderBottom: "0"}} src="acilkombiservisi.com.tr" width="100%" height="100%" />)
    }
   }
  return (
    <div className="admin">
      <header
        role="banner"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontSize: "2rem", margin: "20px" }}>
          <b style={{ color: "#2A3542" }}>ACİL</b> KOMBİ SERVİSİ
        </div>
        <button
          onClick={() => setCategory("")}
          style={{
            fontSize: "1rem",
            margin: "20px",
            padding: "10px",
            borderRadius: "8px",
            backgroundColor: "#2A3542",
            color: "white",
            cursor: "pointer",
          }}
        >
          Canlı Önizleme
        </button>
      </header>
      <nav role="navigation">
        <ul className="main">
          <li onClick={() => setCategory("tema")} className="dashboard">
            <a >Tema Yönetimi</a>
          </li>
          <li  onClick={() => setCategory("bilgilendirme")} className="edit">
            <a>Bilgilendirme</a>
          </li>
          <li  onClick={() => setCategory("servisler")} className="edit">
            <a>Servisler</a>
          </li>
          <li onClick={() => setCategory("hakkımızda")} className="users">
            <a>Hakkımızda</a>
          </li>
          <li onClick={() => setCategory("iletisim")} className="comments">
            <a >İletişim</a>
          </li>
         
          <li className="write">
            <a onClick={() => setCategory("blog")}>Blog Yazıları</a>
          </li>
        </ul>
      </nav>
      <main role="main">{CategoryComponent()}</main>
    </div>
  );
};

export default AdminPage;
