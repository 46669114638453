import { useEffect, useState } from 'react'



const Slider = ({slider}) => {
  const [slide, setSlide] = useState(0)
 
  useEffect(() => {
    setSlide(slider)
    document.getElementById("slider").style.display = "block"
  },[slide,slider])
}

export default Slider