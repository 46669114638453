import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DBkombi } from "../../db/Firebase-Collection";


const HakkımızdaPanel = ({about, aboutSlide}) => {
  const [abauttitle, setAbouttitle] = useState(about?.title);
  const [abautdescription, setAboutdescription] = useState(about?.description);
  const [abautslogan, setAboutslogan] = useState(about?.slogan);
  const [abautSlide1, setAboutSlide1] = useState(aboutSlide[0]);
  const [abautSlide2, setAboutSlide2] = useState(aboutSlide[1]);
  const [abautSlide3, setAboutSlide3] = useState(aboutSlide[2]);
  useEffect(() => {
    var head = document.getElementById("default_header");
    head.style.display = "none";
  }, [])
  const ChangeAbout = () =>{
    updateDoc(doc(DBkombi, "enCC1UF9WmYIsYOc8Wle"), {
      about: {
        title: abauttitle,
        description: abautdescription,
        slogan: abautslogan,
      }
  });
  alert("Değişiklikler Kaydedildi")
}
  const ChangeAboutSlide = () =>{
    updateDoc(doc(DBkombi, "enCC1UF9WmYIsYOc8Wle"), {
      aboutSlide: {
        0: abautSlide1,
        1: abautSlide2,
        2: abautSlide3,
      }})
      alert("Değişiklikler Kaydedildi")
  }
   
return (
<>
<div className="ThemeSlice">
    <div className="title">Hakkımızda Sayfası</div>
    <form style={{backgroundColor: "#2a35425f", padding: "7px", borderRadius: "8px"}}>
        <label>Başlık</label>
        <input type="text" value={abauttitle} onChange={(e) => setAbouttitle(e.target.value)} />
        
    </form>
    <form >
        <label>Slogan</label>
    <input style={{width: "100%"}} type="text" value={abautslogan} onChange={(e) => setAboutslogan(e.target.value)} />
    </form>
    <form>
    <label>Paragraf</label>

    <textarea style={{width: "100%"}} type="text" rows={4} className='description' value={abautdescription} onChange={(e) => setAboutdescription(e.target.value)} />
    </form>
    <div className="submit">
        <button onClick={ChangeAbout}>Kaydet</button>
      </div>
</div>
<div className="ThemeSlice">
    <div className="title">Hakkımızda Bilgiler</div>
    <div style={{display:"flex", marginTop: "20px", marginTop: "20px"}}>
    <img src="images/esisat.svg" className="img-responsive" alt="#" width={150} height={150} style={{margin: "10px", padding: "10px"}} />
      <textarea name="" id="" rows="5" style={{width: "100%", margin: "10px", padding: "10px"}}  value={abautSlide1} onChange={(e) => setAboutSlide1(e.target.value)}/>
    </div>
    <div style={{display:"flex", marginTop: "20px"}}>
    <img src="images/bakım.png" className="img-responsive" alt="#" width={150} height={150} style={{margin: "10px", padding: "10px"}} />
    <textarea name="" id="" rows="5" style={{width: "100%", margin: "10px", padding: "10px"}} value={abautSlide2} onChange={(e) => setAboutSlide2(e.target.value)}/>

    </div>
    <div style={{display:"flex", marginTop: "20px"}}>
    <img src="images/servis.png" className="img-responsive" alt="#" width={150} height={150} style={{margin: "10px", padding: "10px"}} />
    <textarea name="" id="" rows="5" style={{width: "100%", margin: "10px", padding: "10px"}} value={abautSlide3} onChange={(e) => setAboutSlide3(e.target.value)}/>
    </div>
    <div className="submit">
        <button onClick={ChangeAboutSlide}>Kaydet</button>
      </div>
</div>
 
</>
);
};

export default HakkımızdaPanel;
