import { useEffect } from "react"
import About from "../components/About"
import CustomAbout from "../components/CustomAbout"
import CustomPageHead from "../components/CustomPageHead"

const AboutPage = ({about}) => {
  useEffect(() => {
    document.querySelector("#hakkımızda").classList.add("active");
    document.title = "Hakkımızda | Acil Kombi Servisi";
  }, [])
  
  return (
   <>
   <CustomPageHead pageName={"hakkımızda"}/>
   <CustomAbout about={about}/>
   </>
  )
}

export default AboutPage