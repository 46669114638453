import { useEffect } from "react";


const BlogPanel = ({AllData}) => {

  useEffect(() => {
    document.getElementById("default_header").style.display = "none";
  }, [])

   
return (
<>
<div className="ThemeSlice">
    <div className="title">Bu Alan Hazırlanmaktadır...</div>
   
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "500px"}}> <img id="hazırlanıyor" style={{backgroundColor: AllData.primaryColor, borderRadius: "3 0px"}} width="300" height="200" src="images/loading.png" alt="#" /> </div>
  </div>
 
</>
);
};

export default BlogPanel;
