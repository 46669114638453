import { addDoc, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { DBkombi } from "../../db/Firebase-Collection";

const ChangeThemeColor = ({AllData}) => {
  const [color, setColor] = useState(AllData.primaryColor)
  const [slider1, setSlider1] = useState(AllData.slider[0])
  const [slider2, setSlider2] = useState(AllData.slider[1])

  useEffect(() => {
    document.getElementById("default_header").style.display = "none";
  }, [])
   function ChangeColor() {
    updateDoc(doc(DBkombi, "enCC1UF9WmYIsYOc8Wle"), {
      primaryColor: color,
    });
    alert("Değişiklikler Kaydedildi")
  }
  function ChangeSlider() {
    updateDoc(doc(DBkombi, "enCC1UF9WmYIsYOc8Wle"), {
      slider: [slider1, slider2],
    });
  }
return (
<>
<div className="ThemeSlice">
    <div className="title">Tema Rengi</div>
   <div className="colors">
    <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
    <div className="submit">
      <button onClick={ChangeColor}>Kaydet</button>
    </div>
   </div>
   
  </div>
  {/* <div className="ThemeSlice">
    <div className="title">Slayt Yönetimi</div>
   <form >
    <label>Slayt 1</label>
   <input type="text" placeholder="resim url" value={slider1?.imgUrl} onChange={(e) => setSlider1({...slider1, imgUrl: e.target.value})} />
   <input type="text" placeholder="başlık" value={slider1?.title} onChange={(e) => setSlider1({...slider1, title: e.target.value})} />
   <input type="text" placeholder="Açıklama" value={slider1?.description} onChange={(e) => setSlider1({...slider1, description: e.target.value})} />
   </form>
   <form >
    <label>Slayt 2</label>
    <input type="text" placeholder="resim url" value={slider2?.imgUrl} onChange={(e) => setSlider2({...slider2, imgUrl: e.target.value})} />
   <input type="text" placeholder="başlık" value={slider2?.title} onChange={(e) => setSlider2({...slider2, title: e.target.value})} />
   <input type="text" placeholder="Açıklama" value={slider2?.description} onChange={(e) => setSlider2({...slider2, description: e.target.value})} />
   </form>
    <div className="submit">
      <button onClick={ChangeSlider}>Kaydet</button>
    </div>
  </div> */}
</>
);
};

export default ChangeThemeColor;
