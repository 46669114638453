import { useEffect } from "react"

const Header = ({contact}) => {
  useEffect(() => {
    var head = document.getElementById("default_header");
    if(head) head.style.display = "block";
    var sabit = document.getElementById("sabithatDB")
    var tel = document.getElementById("gsmDB")
    var whatsapp = document.getElementById("wpDB")
    if(sabit){
      sabit.setAttribute("href", "tel:"+contact?.sabitHat)
      sabit.innerHTML = contact?.sabitHat
    }
    if(tel){
      tel.setAttribute("href", "tel:"+contact?.gsm)
      tel.innerHTML = contact?.gsm
    }
    if(whatsapp){
      whatsapp.setAttribute("href", "https://api.whatsapp.com/send?phone="+contact?.whatsapp)
      whatsapp.innerHTML = contact?.whatsapp.slice(2,contact?.whatsapp.length)
    }
  }, [contact])

}

export default Header