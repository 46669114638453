
const About = ({aboutSlide}) => {
  return (
    <div className="section padding_layout_1 testmonial_section white_fonts">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="full">
            <div className="main_heading text_align_left">
              <h2 style={{textTransform: 'none'}}>Hakkımızda</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-10">
          <div className="full">
            <div id="testimonial_slider" className="carousel slide" data-ride="carousel">
              <ul className="carousel-indicators">
                
              </ul>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="testimonial-container">
                    <div className="testimonial-content" style={{paddingTop: '20px', fontSize: '1.25rem'}}>{aboutSlide ? aboutSlide[0]: "Servis hizmeti aldığınız Kombi ve Klimalar için 1 Yıl garanti tüm Kombi ve Klimalarınıza Çağrı Merkezimiz ile Destek Hizmeti tecrübeli ve donanımlı servis elemanları 14 Yıllık Sektör Tecrübesi, Merkezi Isıtma Sistemleri Klimalarınıza Ve Kombilerinize Servis Hizmeti Vermekteyiz."}</div>
                    <div className="testimonial-photo"> <img src="images/esisat.svg" className="img-responsive" alt="#" width={150} height={150} /> </div>
                    <div className="testimonial-meta">
                      <h4 style={{height: '20px'}} />
                      <span style={{height: '20px'}} className="testimonial-position" /> </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonial-container">
                    <div className="testimonial-content" style={{paddingTop: '20px', fontSize: '1.25rem'}}>{aboutSlide ? aboutSlide[1]:"Cihazlarınızda Oluşan Herhangi Bir Nedenden Dolayı Türkiye'nin Her Yerinden Çağrı Merkezimizi arayabilir Yada Servis Talep Formunu Doldurarak Servisimize Ulaşabilirsiniz. Servis Noktası, Doğruluğuyla , Tüm Orjinal Ve Yedek Parçalar Ekonomik Fiyatlara Bulunmaktadır."}  </div>
                    <div className="testimonial-photo"> <img src="images/bakım.png" className="img-responsive" alt="#" width={150} height={150} /> </div>
                    <div className="testimonial-meta">
                      <h4 style={{height: '20px'}} />
                      <span style={{height: '20px'}} className="testimonial-position" /> </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="testimonial-container">
                    <div className="testimonial-content" style={{paddingTop: '20px', fontSize: '1.25rem'}}>{aboutSlide ? aboutSlide[2]:"Cihazlarınızda Oluşan Herhangi Bir Nedenden Dolayı Türkiye'nin Her Yerinden Çağrı Merkezimizi arayabilir Yada ServiAlanında Deneyimli Ekip Ve Profesyonel Kadrosuylaakım, Onarım, Arıza, Tamir Ve Teknik Servis Hizmeti Sunmaktayız. Profesyonel ustalarla birlikte yapılmakta olan bu hizmet üzerinden klimanın ömrünün çoğalması aynı zamanda performansının artırılması amaçlanmaktadır.s Talep Formunu Doldurarak Servisimize Ulaşabilirsiniz. Servis Noktası, Doğruluğuyla , Tüm Orjinal Ve Yedek Parçalar Ekonomik Fiyatlara Bulunmaktadır."}</div>
                    <div className="testimonial-photo"> <img src="images/servis.png" className="img-responsive" alt="#" width={150} height={150} /> </div>
                    <div className="testimonial-meta">
                      <h4 style={{height: '20px'}} />
                      <span style={{height: '20px'}} className="testimonial-position" /> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="full"> </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default About