import React from 'react'

const WhatsApp = ({contact}) => {
  return (
    <div className="section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="full">
            <div className="contact_us_section">
              <div className="call_icon"> <img src="images/wp.png" width={70} alt="#" /> </div>
              <div className="inner_cont">
                <h2>Bize WhatsApp'tan ulaşın</h2>
                <p>İstediğiniz konu hakkında bilgi almak için whatsapp'tan ulaşın..</p>
              </div>
              <div className="button_Section_cont"> <a className="btn dark_gray_bt" target="_blank" href={`https://api.whatsapp.com/send?phone=${contact?.whatsapp}`}>Bize Ulaşın </a> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default WhatsApp