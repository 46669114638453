
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXZ0gF9Qc47RSn1Hx5_7tlMK9gPKpm0ec",
  authDomain: "acilkombiservisi-fc030.firebaseapp.com",
  projectId: "acilkombiservisi-fc030",
  storageBucket: "acilkombiservisi-fc030.appspot.com",
  messagingSenderId: "196233903743",
  appId: "1:196233903743:web:37150359817b990ec1494a",
  measurementId: "G-HWJRCK23FT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
export default  db;