
const CustomContact = ({contact}) => {
  return (
    <div className="section padding_layout_1">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: '20px'}}>
            <div dangerouslySetInnerHTML={{ __html: contact?.map }}></div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12" style={{marginLeft: '20px'}}>
              <div className="row">
                <div className="full">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 adress_cont margin_bottom_30">
                    <div className="information_bottom left-side margin_bottom_20_all">
                      <div className="icon_bottom"> <i className="fa fa-road" aria-hidden="true" /> </div>
                      <div className="info_cont">
                        <h4>{contact?.address || "Bahçelievler/İstanbul"}</h4>
                      </div>
                    </div>
                    <div className="information_bottom left-side margin_bottom_20_all">
                      <div className="icon_bottom"> <i className="fa fa-user" aria-hidden="true" /> </div>
                      <div className="info_cont">
                        <h4>Sabit Telefon : {contact?.sabitHat || "0212 639 81 85"}</h4>
                        <p>Gsm : {contact?.gsm || "0532 440 53 64"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default CustomContact