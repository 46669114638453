import { useState } from "react";

const Description = ({AllData}) => {
  const [plane , setPlane] = useState(false);
  const [star , setStar] = useState(false);
  const [analitics , setAnalitics] = useState(false);
  return (
   <>
    <div className="section padding_layout_1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="full">
                <div className="main_heading text_align_center">
                  <h2>{AllData?.bilgilendirme ? AllData.bilgilendirme[4].title : "Profesyonel Servİs Desteğİmİzle Hİzmetİnİzdeyİz"}
                  </h2>
                  <p className="large">{AllData?.bilgilendirme ? AllData?.bilgilendirme[4].description : "Profesyonel bir servis için; Kurumsal firma tercih edin!"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="full margin_bottom_30">
                <div className="accordion border_circle">
                  <div className="bs-example">
                    <div className="panel-group" id="accordion">
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <p className="panel-title"> <a id="firstDesc" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"><i className="fa fa-bar-chart" aria-hidden="true" /> <span style={{color: "#fff"}}> {AllData?.bilgilendirme ? AllData?.bilgilendirme[0].title : "Klima & Kombi Bakım Ve Onarım Hizmetleri"}</span><i className="fa fa-angle-down" /></a> </p>
                        </div>
                        <div id="collapseOne" className="panel-collapse collapse in">
                          <div className="panel-body">
                            <p>{AllData?.bilgilendirme ? AllData?.bilgilendirme[0].description : "Klima ve Kombi bakım ve onarım hizmetleri kapsamında, tecrübeli ve uzman teknikerlerle birlikte çalışılmaktadır. Bu alanda klimanın markası, özellikleri ve arızası analiz edildikten sonra onarım işlemi yapılır. Onarım hizmetinde klimanın iç kısımları ve boru tesisat sistemleri incelenerek, performans düşüklüğünün nereden kaynaklandığı analiz edilmelidir. Profesyonel ustalarla birlikte yapılmakta olan bu hizmet üzerinden klimanın ömrünün çoğalması aynı zamanda performansının artırılması amaçlanmaktadır."} </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <p className="panel-title"> <a onClick={() => setPlane(!plane)} style={{backgroundColor: plane && AllData?.primaryColor, borderColor: plane && AllData?.primaryColor}} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"><i className="fa fa-plane" />{AllData?.bilgilendirme ? AllData?.bilgilendirme[1].title : "Kombi Bakımını Yaptırmak Neden Önemlidir?"} <i className="fa fa-angle-down" /></a> </p>
                        </div>
                        <div id="collapseTwo" className="panel-collapse collapse">
                          <div className="panel-body">
                            <p>{AllData?.bilgilendirme ? AllData?.bilgilendirme[1].description : "Cihazınızın, devreye alım tarihinden itibaren kullanım sıklığı da dikkate alınarak periyodik bakımlarının yaptırılması; yakıt tasarrufu sağlar, cihazınızın ömrünü uzatır, olası arızaları en aza indirir, güvenlik ve emniyet sistemlerinin kontrolleri yapılmış olur. Önleyici bakım, cihazın çalışma durumlarına göre, 3 aylık, 6 aylık veya 1 yıllık periyodlarda yapılabilir."} </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <p className="panel-title"> <a onClick={() => setStar(!star)} style={{backgroundColor: star && AllData?.primaryColor, borderColor: star && AllData?.primaryColor}}  data-toggle="collapse" data-parent="#accordion" href="#collapseThree"><i className="fa fa-star" />{AllData?.bilgilendirme ? AllData?.bilgilendirme[2].title :"Klimanın Ömrünü Artıran Hizmetler"} <i className="fa fa-angle-down" /></a> </p>
                        </div>
                        <div id="collapseThree" className="panel-collapse collapse">
                          <div className="panel-body">
                            <p>{AllData?.bilgilendirme ? AllData?.bilgilendirme[2].description :"Klimanın ömrünü artıran hizmetler öncellikle kurulum aşamasından, sökme aşamasına kadar bütün detaylar kapsamaktadır. Narin teknolojik aletler olarak, profesyonel ustalar tarafından kurulumun yapılması ya da yer değişikliği için söküm işleminin yapılması gerekmektedir. Yılda bir kere klima temizliğine yer verilerek, ömrünün uzatılması sağlanacağı gibi, arıza ve çok sesli çalışma durumlarında da aramanız önemli olacaktır. Bu önem ilk olarak maliyet açısından önlem almanızı hem de klima modelinin daha fazla arızalanmadan zararlı bir kullanım oluşturmamasını sağlayacaktır. Profesyonel hizmet kapsamında, tamir, bakım ve onarım işlemleri için uzman ekiplerle çalışılmaktadır."} </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <p className="panel-title"> <a onClick={() => setAnalitics(!analitics)} style={{backgroundColor: analitics && AllData?.primaryColor, borderColor: analitics && AllData?.primaryColor}}   data-toggle="collapse" data-parent="#accordion" href="#collapseFour"><i id="bela" className="fa fa-bar-chart" aria-hidden="true" />{AllData?.bilgilendirme ? AllData?.bilgilendirme[3].title :"Kombinin Daha Verimli Çalışması İçin Bakım Şart!"}<i className="fa fa-angle-down" /></a> </p>
                        </div>
                        <div id="collapseFour" className="panel-collapse collapse in">
                          <div className="panel-body">
                            <p>{AllData?.bilgilendirme ? AllData?.bilgilendirme[3].description :"Kombi tamiri yanı sıra bakım konusunda da ayrıca dikkatli olunması gerekiyor. Bakım adımının her kış mevsimi öncesinde senelik olarak tekrarlanması gerekir. Bu alanda yoğun biçimde kullanan kişilerin hem kış mevsiminin başlangıcında hem de sorunda bakım adımını tamamlatması daha mantıklı ve verimli olacaktır. Pratik ve sorunsuz erişim adımının tamamlanması için en doğru noktalardan birinde bulunuyorsunuz."} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="full" style={{marginTop: '35px'}}>
                <h3>{AllData?.bilgilendirme ? AllData?.bilgilendirme[5].title :"Bütçenizi Düşünen Uygun Fiyatlar!"}</h3>
                <p>{AllData?.bilgilendirme ? AllData?.bilgilendirme[5].description :"Ürünün markası ne olursa olsun bütünüyle orijinal veya gerçek parçaların kullanılmasına özen gösterilir. Değiştirildikten sonra garantisi olduğu için bir sorun yaşanması durumunda çalışanlarımızdan yardım isteyebilirsiniz."}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

export default Description