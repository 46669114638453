
const CustomAbout = ({about}) => {
  return (
    <div className="section padding_layout_1">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="full">
            <div className="main_heading text_align_center">
              <h2>{about && about?.title || "ACİL KOMBİ SERVİSİ"}</h2>
              <p className="large">{about && about?.slogan || `Kombi Teknik Servisi – Kombi bakım, montaj ve tamir işleriniz için emrinizdeyiz. Kombi tamir, Kombi bakım, Kombi montaj hizmetlerinde kalite ve güvenin adresi.`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row about_blog" style={{alignItems: "center"}}>
        <div className="col-lg-6 col-md-6 col-sm-12 about_cont_blog">
          <div className="full text_align_left">
            <p>{about && about?.description || `Servis hizmeti aldığınız Kombi ve Klimalar için 1 Yıl garanti tüm Kombi ve Klimalarınıza Çağrı
              Merkezimiz ile Destek Hizmeti tecrübeli ve donanımlı servis elemanları 14 Yıllık Sektör
              Tecrübesi, Merkezi Isıtma Sistemleri Klimalarınıza Ve Kombilerinize Servis Hizmeti
              Vermekteyiz. Cihazlarınızda Oluşan Herhangi Bir Nedenden Dolayı Türkiye'nin Her Yerinden
              Çağrı Merkezimizi arayabilir Yada Servis Talep Formunu Doldurarak Servisimize
              Ulaşabilirsiniz. Servis Noktası, Doğruluğuyla , Tüm Orjinal Ve Yedek Parçalar Ekonomik
              Fiyatlara Bulunmaktadır. Alanında Deneyimli Ekip Ve Profesyonel Kadrosuyla İstanbul
              Geneline Bakım, Onarım, Arıza, Tamir Ve Teknik Servis Hizmeti Sunmaktayız..`}</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 about_feature_img">
          <div className="full text_align_center"> <img className="img-responsive" src={about && about.imgUrl || "images/kombiarkaplan.jpg"} alt="#" /> </div>
        </div>
      </div>
    
    </div>
  </div>
  )
}

export default CustomAbout
