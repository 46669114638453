import React from "react";


const AdminLogin = ({ setIsLoggedIn }) => {
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");

React.useEffect(() => {
    document.querySelector(".loader").style.backgroundColor = "black";
    setTimeout(() => {
      var head = document.getElementById("default_header");
      if(head) head.style.display = "none";
 document.querySelector(".footer_style_2").style.display = "none";

    }, 1500);
}, [])



  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "admin" && password === "acilkombiservisi") {
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", true);
    } else {
      alert("kullanıcı adı veya şifre yanlış");
    }
  };
  return (
    <div className="loginPage">
      <div className="fullscreen-container">
        <div className="container">
          <div className="heading"><b>Acil</b> <p>Kombi Servisi</p></div>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <label htmlFor="name">Kullanıcı</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Şifre</label>
                <input
                  type="Password"
                  id="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit">Giriş Yap</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
