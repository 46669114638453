import React, { useEffect } from 'react'

const Servisler = ({services}) => {
  useEffect(() => {
    console.log(services);
  }, [services])
  
  return (
    <div className="section padding_layout_1 light_silver gross_layout">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="full">
            <div className="main_heading text_align_left">
              <h2>{services && services[6].title || "HİZMETLERİMİZ"}</h2>
              <p className="large"> {services && services[6].description || "Kombi Teknik Servisi – Kombi bakım, montaj ve tamir işleriniz için emrinizdeyiz. Kombi tamir, Kombi bakım, Kombi montaj hizmetlerinde kalite ve güvenin adresi."}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="full">
                <div className="service_blog_inner">
                  <div className="icon text_align_left"><img src="images/servis.png" alt="#" /></div>
                  <h4 className="service-heading">{services && services[0].title || "Kombi Servisi"}</h4>
                  <p>{services && services[0].description ||"Cihazınızın, devreye alım tarihinden itibaren kullanım sıklığı da dikkate alınarak bakımlarının yaptırılması; yakıt tasarrufu sağlar, cihazınızın ömrünü uzatır."} </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="full">
                <div className="service_blog_inner">
                  <div className="icon text_align_left"><img src="images/bakım.png" alt="#" /></div>
                  <h4 className="service-heading">{services && services[1].title || "Kombi Bakım ve Tamiri"}</h4>
                  <p>{services && services[1].description || "Bu alanda kombinin markası, özellikleri ve arızası analiz edildikten sonra onarım işlemi yapılır. "}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="full">
                <div className="service_blog_inner">
                  <div className="icon text_align_left"><img src="images/klima.png" alt="#" /></div>
                  <h4 className="service-heading">{services && services[2].title || "Klima Servisi"}</h4>
                  <p>{services && services[2].description || "Yılda bir kere klima temizliğine yer verilerek, ömrünün uzatılması sağlanacağı gibi, arıza ve çok sesli çalışma durumlarında da aramanız önemli olacaktır."}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="full">
                <div className="service_blog_inner">
                  <div className="icon text_align_left"><img src="images/esisat.svg" alt="#" /></div>
                  <h4 className="service-heading">{services && services[3].title || "Tesisat Temizliği"}</h4>
                  <p>{services && services[3].description || "Verim artmasıyla yakıt tasarrufunda ve ısıtma da %20-30'a varan artışlar gözlemlenir."}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="full">
                <div className="service_blog_inner">
                  <div className="icon text_align_left"><img src="images/kazan.svg" alt="#" /></div>
                  <h4 className="service-heading">{services && services[4].title || "Tesisat Temizliği"}</h4>
                  <p>{services && services[4].description || "Kazan temizliği düzenli olarak yaptırıldığı takdirde, kazanın içindeki doğal gaz metale daha iyi temas eder ve suyun daha erken ısınmasını sağlar. ."}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="full">
                <div className="service_blog_inner">
                  <div className="icon text_align_left"><img src="images/petek.svg" alt="#" /></div>
                  <h4 className="service-heading">{services && services[5].title || "Buharlı Petek Temizliği"}</h4>
                  <p>{services && services[5].description || "Buharlı petek temizleme petek aralarında biriken toz ve bakterileri yoğun buhar yöntemiyle temizleme işlemidir bu işlem yapılırken yüksek basınçlı makineler kullanılmalıdır."}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Servisler