import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { DBkombi } from '../../db/Firebase-Collection'

const BilgilendirmePanel = ({AllData}) => {
    const [bilgi1, setBilgi1] = useState(AllData && AllData.bilgilendirme && AllData.bilgilendirme[0])
    const [bilgi2, setBilgi2] = useState(AllData &&  AllData.bilgilendirme && AllData.bilgilendirme[1])
    const [bilgi3, setBilgi3] = useState(AllData &&  AllData.bilgilendirme && AllData.bilgilendirme[2])
    const [bilgi4, setBilgi4] = useState(AllData &&   AllData.bilgilendirme && AllData.bilgilendirme[3])
    const [bilgi5, setBilgi5] = useState(AllData &&   AllData.bilgilendirme && AllData.bilgilendirme[4])
    const [bilgi6, setBilgi6] = useState(AllData &&   AllData.bilgilendirme && AllData.bilgilendirme[5])
    useEffect(() => {
      document.getElementById("default_header").style.display = "none";
    }, [])
  const ChangeBilgilendirme = () =>{
  
    updateDoc(doc(DBkombi, "enCC1UF9WmYIsYOc8Wle"), {
      bilgilendirme: [
        {
          title: bilgi1.title,
          description: bilgi1.description,
        },
        {
          title: bilgi2.title,
          description: bilgi2.description,
        },
        {
          title: bilgi3.title,
          description: bilgi3.description,
        },
        {
          title: bilgi4.title,
          description: bilgi4.description,
        },
        {
          title: bilgi5.title,
          description: bilgi5.description,
        },
        {
          title: bilgi6.title,
          description: bilgi6.description,
        }
      ]
    });
    alert("Değişiklikler Kaydedildi")
  }

  return (
    <>
      <div className="ThemeSlice">
        <div className="title">Bilgilendirme</div>
        <form style={{backgroundColor: "#2a35425f", padding: "7px", borderRadius: "8px"}}>
            <label>Başlık</label>
            <input type="text" value={bilgi5?.title} onChange={(e) => setBilgi5({...bilgi5, title: e.target.value})} />
            <input type="text" className='description' value={bilgi5?.description} onChange={(e) => setBilgi5({...bilgi5, description: e.target.value})} />
        </form>
        <form style={{ padding: "7px", borderRadius: "8px"}}>
            <label>Yan Taraf</label>
            <input type="text" value={bilgi6?.title} onChange={(e) => setBilgi6({...bilgi6, title: e.target.value})} />
            <textarea type="text" rows={3} className='description' value={bilgi6?.description} onChange={(e) => setBilgi6({...bilgi6, description: e.target.value})} />
        </form>
        <form style={{backgroundColor: "#2a35425f", padding: "7px", borderRadius: "8px"}}>
            <label><i class="fa fa-bar-chart"></i>  1</label>
            <input type="text" value={bilgi1?.title} onChange={(e) => setBilgi1({...bilgi1, title: e.target.value})} />
            <textarea type="text" rows={6} className='description' value={bilgi1?.description} onChange={(e) => setBilgi1({...bilgi1, description: e.target.value})} />
        </form>
        <form style={{ padding: "7px", borderRadius: "8px"}}>
            <label><i class="fa fa-plane"></i>  2</label>
            <input type="text" value={bilgi2?.title} onChange={(e) => setBilgi2({...bilgi2, title: e.target.value})} />
            <textarea type="text" rows={4} className='description' value={bilgi2?.description} onChange={(e) => setBilgi2({...bilgi2, description: e.target.value})} />
        </form>
        <form style={{backgroundColor: "#2a35425f", padding: "7px", borderRadius: "8px"}}>
            <label><i class="fa fa-star"></i>  3</label>
            <input type="text" value={bilgi3?.title} onChange={(e) => setBilgi3({...bilgi3, title: e.target.value})} />
            <textarea type="text" rows={5} className='description' value={bilgi3?.description} onChange={(e) => setBilgi3({...bilgi3, description: e.target.value})} />
        </form>
        <form style={{ padding: "7px", borderRadius: "8px"}}>
            <label><i class="fa fa-bar-chart"></i>  4</label>
            <input type="text" value={bilgi4?.title} onChange={(e) => setBilgi4({...bilgi4, title: e.target.value})} />
            <textarea type="text" rows={4} className='description' value={bilgi4?.description} onChange={(e) => setBilgi4({...bilgi4, description: e.target.value})} />
        </form>
        <div className="submit">
        <button onClick={ChangeBilgilendirme} >Kaydet</button>
      </div>
      </div>
     
    </>
  );
}

export default BilgilendirmePanel