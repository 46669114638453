
const Loading = () => {

  setTimeout(() => {
    var loader = document.querySelector(".loader");
    if (loader) {
      loader.style.display = "none"
    }
  }, 4000);
  return (
    <div className="loader"><img width="200" src="images/loading.png" alt="#" /> <div className="shadow"></div></div> 

  )
}

export default Loading