import React from 'react'

const ColorComponent = ({primaryColor}) => {
    localStorage.setItem("primaryColor", primaryColor);
    const backgroundElements = [
  
        "#accordion .panel.panel-default .panel-heading p a",
      ".header_top",
      "#navbar_menu ul ul li a:hover",
      "#navbar_menu ul ul li a:focus",
      ".full_slider_inner",
      ".bg_load",
      ".theme_color_bg",
      ".price_table_active .price_head",
      "a.btn.dark_gray_bt:hover",
      "a.btn.dark_gray_bt:focus",
      ".social_icon_style_2 ul li a:hover",
      ".social_icon_style_2 ul li a:focus",
      ".progress-bar",
      ".tags a:hover",
      ".tags a:focus",
      ".pagination>.active>a",
      ".pagination>.active>a:focus",
      ".pagination>.active>a:hover",
      ".pagination>.active>span",
      ".pagination>.active>span:focus",
      ".pagination>.active>span:hover",
      ".icon_feature",
      ".theme_bg",
      ".btn.sqaure_bt",
      "footer h2::after",
      ".footer_mail-section .field button.button_custom",
      ".cprt",
      ".tp-caption.tp-shape.tp-shapewrapper.tp-resizeme.rs-parallaxlevel-0",
      "a.btn.main_bt",
      "button.btn.main_bt",
      "a.btn.main_bt:hover",
      "a.btn.main_bt:focus",
      "button.btn.main_bt:hover",
      "button.btn.main_bt:focus",
      ".tp-bannertimer",
      ".main_heading::after",
      "#accordion .panel.panel-default .panel-heading p a[aria-expanded='true']",
      "#accordion .panel.panel-default .panel-heading p a",
      ".categary li:hover",
      ".categary li:focus",
      ".team_blog_colum:hover",
      ".team_blog_colum:focus",
      ".service_blog_inner2 .icon",
      ".product_detail .tab_bar_section ul.nav.nav-tabs li a.active",
      ".product-table .bt_main",
      ".shopping-cart .button",
      ".bt_main",
      ".scrool",
      ".loginPage .fullscreen-container button",
    ];
    const colorElements = [

        "#bela",
      ".panel-title a i[aria-expanded='true']",
      ".fa-user",
      ".fa-bar-chart",
      ".fa-star",
      ".fa-plane",
      ".panel.panel-default .panel-heading p a>i",
      ".header_bottom .container .row .col-lg-3 .logo a",
      "#navbar_menu > ul > li a.active",
      "#navbar_menu > ul > li:hover > a",
      "#navbar_menu > ul > li:focus > a",
      "footer p a:hover,footer p a:focus",
      ".footer-menu li a:hover",
      ".footer-menu li a:focus",
      "footer ul.social_icons li a:hover",
      "footer ul.social_icons li a:focus",
      ".breadcrumb li a",
      ".logo",
      ".dark_gray.white_fonts h2",
      ".counter i",
      "#accordion .panel.panel-default .panel-heading p a[aria-expanded='false'] i",
      "#accordion .panel.panel-default .panel-heading p a>i",
      ".price_table_active .price_no",
      ".blog_feature_cantant li",
      ".blog_feature_cantant li a:hover",
      ".read_more:hover",
      ".read_more:focus",
      ".service_blog_inner2:hover .icon",
      ".service_blog_inner2:focus .icon",
      ".tab-info.login-section p a:hover",
      ".tab-info.login-section p a:focus",
      ".tab-info.coupon-section p a:focus",
      ".adress_cont i",
      "#navbar_menu > ul > li a.active",
      "#navbar_menu > ul > li:hover > a",
      "#navbar_menu > ul > li:focus > a",
      "footer p a:hover",
      "footer p a:focus",
      ".footer-menu li a:hover",
      ".footer-menu li a:focus",
      "footer ul.social_icons li a:hover",
      "footer ul.social_icons li a:focus",
      ".breadcrumb li a",
    ];
    const borderElements = [
        "#accordion .panel.panel-default .panel-heading p a[aria-expanded='true']",
        "#accordion .panel.panel-default .panel-heading p a",
        "#navbar_menu ul ul",
        "hr.dottad_border",
        ".social_icon_style_2 ul li a:hover",
        ".social_icon_style_2 ul li a:focus",
        ".tags a:hover",
        ".tags a:focus",
        ".pagination>.active>a",
        ".pagination>.active>a:focus",
        ".pagination>.active>a:hover",
        ".pagination>.active>span",
        ".pagination>.active>span:focus",
        ".pagination>.active>span:hover",
        ".comment_section",
        ".boder_dottat_small",
        ".tags a:hover",
        ".tags a:focus",
        ".blog_feature_cantant",
        ".product_list:hover",
        ".product_list:focus",
        ".team_blog_colum:hover",
        ".team_blog_colum:focus",
        ".service_blog_inner2 .icon",
        ".service_blog_inner2:hover .icon",
        ".service_blog_inner2:focus .icon",
    ]
    React.useEffect(() => {
        backgroundElements.map((element) => {
            var x = document.querySelector(element)
            if (x) {
                x.style.background = primaryColor
            }
        })
        colorElements.map((element) => {
            var x = document.querySelector(element)
            if (x) {
                x.style.color = primaryColor
            }
        })
        borderElements.map((element) => {
            var x = document.querySelector(element)
            if (x) {
                x.style.borderColor = primaryColor
            }
        })
        var loader = document.querySelector(".loader")
        var testmonial = document.querySelector(".testmonial_section")
        var body = document.querySelector(".App")
        var active = document.querySelector(".activeli")
        if (loader) {
            loader.style.backgroundColor = primaryColor
            
        }
        if (testmonial) {
            testmonial.style.backgroundColor = primaryColor
        }
        if (body) {
            body.style.backgroundColor = primaryColor
        }
        if (active) {
            active.style.backgroundColor = primaryColor
            active.style.color = "#ffff"

        }
    }, [primaryColor])
}

export default ColorComponent