import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { DBkombi } from "../../db/Firebase-Collection";

const ContactPanel = ({contact}) => {
  const [sabitHat, setSabitHat] = useState(contact.sabitHat)
  const [gsm, setGsm] = useState(contact.gsm)
  const [email, setEmail] = useState(contact.email)
  const [address, setAdress] = useState(contact.address)
  const [whatsapp, setWhatsapp] = useState(contact.whatsapp)
  const [map, setMap] = useState(contact.map)
  useEffect(() => {
    document.getElementById("default_header").style.display = "none";
  }, [])
  const ChangeContact = () =>{
  
    updateDoc(doc(DBkombi, "enCC1UF9WmYIsYOc8Wle"), {
      contact: {
        sabitHat: sabitHat,
        gsm: gsm,
        email: email,
        address: address,
        whatsapp: whatsapp,
        map: map,
      }
    });
    alert("Değişiklikler Kaydedildi")
  }

return (
  <>
    <div className="ContactSlice">
      <div className="title">İletişim Yönetimi</div>
      <form>
        <div>
          <label>Sabit Hat</label>
          <input
            type="text"
            placeholder="Sabit Hat"
            onChange={(e) => setSabitHat(e.target.value)}
            value={sabitHat}
          />
        </div>
        <div>
          <label>Gsm</label>
          <input
            type="text"
            placeholder="Gsm"
            onChange={(e) => setGsm(e.target.value)}
            value={gsm}
          />
        </div>
        <div>
          <label>Whatsap Hattı</label>
          <input
            type="text"
            placeholder="Whatsap Hattı"
            onChange={(e) => setWhatsapp(e.target.value)}
            value={whatsapp}
          />
        </div>
        <div>
          {" "}
          <label>Mail</label>{" "}
          <input
            type="text"
            placeholder="Mail"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div>
          {" "}
          <label>Adres</label>
          <input
            type="text"
            placeholder="Adres"
            onChange={(e) => setAdress(e.target.value)}
            value={address}
          />
        </div>
        <div>
          <label>Harita</label>
          <textarea
            type="textarea"
            placeholder="Harita"
            onChange={(e) => setMap(e.target.value)}
            value={map}
            style={{ margin: "10px" }}
            rows={4}
          />
        </div>
      </form>
      <div className="submit">
        <button onClick={ChangeContact}>Kaydet</button>
      </div>
    </div>
  </>
);
};

export default ContactPanel;
