import { useEffect } from "react"
import CustomContact from "../components/CustomContact"
import CustomPageHead from "../components/CustomPageHead"
import WhatsApp from "../components/WhatsApp"

const ContactPage = ({contact}) => {
  useEffect(() => {
    document.querySelector("#iletisim").classList.add("active");
    document.title = "İletişim | Acil Kombi Servisi";
  }, [])
  return (
    <>
    <CustomPageHead pageName={"İLETİŞİM"}/>
    <CustomContact contact={contact}/>
    <WhatsApp contact={contact}/>
    </>
  )
}

export default ContactPage