
const Footer = () => {
  const date = new Date().getFullYear();
  return (
    <footer className="footer_style_2" style={{marginTop: "30px"}} >
    <div className="container-fuild">
      <div className="row">
        <div className="cprt">
          <p style={{textAlign: 'end'}}> Acil Kombi Servisi © Copyrights {date}</p>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer