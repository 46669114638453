import { useEffect } from "react"
import About from "../components/About"
import Description from "../components/Description"
import Servisler from "../components/Servisler"
import Slider from "../components/Slider"
import WhatsApp from "../components/WhatsApp"

const HomePage = ({AllData}) => {
  useEffect(() => {
    document.querySelector("#anasayfa").classList.add("active");
  }, [])
  
  return (
   <>
   <Slider slider={AllData?.slider}/>
    <Description AllData={AllData}/>
    <Servisler services={AllData?.services}/>
    <About  aboutSlide={AllData?.aboutSlide}/>
    <WhatsApp contact={AllData?.contact}/>
   </>
  )
}

export default HomePage