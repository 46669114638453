import React, { useEffect } from 'react'
import CustomPageHead from '../components/CustomPageHead'
import Servisler from '../components/Servisler'
import WhatsApp from '../components/WhatsApp'

const Hizmetler = ({AllData}) => {
  useEffect(() => {
    document.querySelector("#hizmetlerimiz").classList.add("active");
    document.title = "Hizmetlerimiz | Acil Kombi Servisi";
  }, [])
  return (
    <>
    <CustomPageHead pageName={"HİZMETLER"}/>
    <Servisler services={AllData?.services}/>
    </>
  )
}

export default Hizmetler