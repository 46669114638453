import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { DBkombi } from '../../db/Firebase-Collection'

const ServislerPanel = ({AllData}) => {
    const [servis1, setServis1] = useState(AllData && AllData.bilgilendirme && AllData.services[0])
    const [servis2, setServis2] = useState(AllData &&  AllData.bilgilendirme && AllData.services[1])
    const [servis3, setServis3] = useState(AllData &&  AllData.bilgilendirme && AllData.services[2])
    const [servis4, setServis4] = useState(AllData &&   AllData.bilgilendirme && AllData.services[3])
    const [servis5, setServis5] = useState(AllData &&   AllData.bilgilendirme && AllData.services[4])
    const [servis6, setServis6] = useState(AllData &&   AllData.bilgilendirme && AllData.services[5])
    const [servis7, setServis7] = useState(AllData &&   AllData.bilgilendirme && AllData.services[6])
    useEffect(() => {
        document.getElementById("default_header").style.display = "none";
      }, [])
    const ChangeServisler = () =>{
       
        updateDoc(doc(DBkombi, "enCC1UF9WmYIsYOc8Wle"), {
            services: [
                {
                    title: servis1.title,
                    description: servis1.description,
                },
                {
                    title: servis2.title,
                    description: servis2.description,
                },
                {
                    title: servis3.title,
                    description: servis3.description,
                },
                {
                    title: servis4.title,
                    description: servis4.description,
                },
                {
                    title: servis5.title,
                    description: servis5.description,
                },
                {
                    title: servis6.title,
                    description: servis6.description,
                },
                {
                    title: servis7.title,
                    description: servis7.description,
                }
            ]
        });
        alert("Değişiklikler Kaydedildi")
    }


  return (
    <>
      <div className="ThemeSlice">
        <div className="title">Servisler</div>
        <form style={{backgroundColor: "#2a35425f", padding: "7px", borderRadius: "8px"}}>
            <label>Başlık</label>
            <input type="text" value={servis7?.title} onChange={(e) => setServis7({...servis7, title: e.target.value})} />
            <input type="text" className='description' value={servis7?.description} onChange={(e) => setServis7({...servis6, description: e.target.value})} />
         
        </form>
        <form>
            <label>1</label>
            <input type="text" value={servis1?.title} onChange={(e) => setServis1({...servis1, title: e.target.value})} />
            <input type="text" className='description' value={servis1?.description} onChange={(e) => setServis1({...servis1, description: e.target.value})} />
        </form>
        <form>
            <label>2</label>
            <input type="text" value={servis2?.title} onChange={(e) => setServis2({...servis2, title: e.target.value})} />
            <input type="text" className='description' value={servis2?.description} onChange={(e) => setServis2({...servis2, description: e.target.value})} />
        </form>
        <form>
            <label>3</label>
            <input type="text" value={servis3?.title} onChange={(e) => setServis3({...servis3, title: e.target.value})} />
            <input type="text" className='description' value={servis3?.description} onChange={(e) => setServis3({...servis3, description: e.target.value})} />
        </form>
        <form>
            <label>4</label>
            <input type="text" value={servis4?.title} onChange={(e) => setServis4({...servis4, title: e.target.value})} />
            <input type="text" className='description' value={servis4?.description} onChange={(e) => setServis4({...servis4, description: e.target.value})} />
        </form>
        <form>
            <label>5</label>
            <input type="text" value={servis5?.title} onChange={(e) => setServis5({...servis5, title: e.target.value})} />
            <input type="text" className='description' value={servis5?.description} onChange={(e) => setServis5({...servis5, description: e.target.value})} />
        </form>
        <form>
            <label>6</label>
            <input type="text" value={servis6?.title} onChange={(e) => setServis6({...servis6, title: e.target.value})} />
            <input type="text" className='description' value={servis6?.description} onChange={(e) => setServis6({...servis6, description: e.target.value})} />
        </form>
      
        <div className="submit">
        <button onClick={ChangeServisler} >Kaydet</button>
      </div>

      </div>
     
    </>
  );
}

export default ServislerPanel